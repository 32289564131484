'use strict';

// eslint-disable-next-line no-multi-assign
window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

$(document).ready(function () {
	if ('initialized' in window && window.initialized) {
		return;
	}

	processInclude(require('./components/menu'));
	processInclude(require('./components/usercentrics'));
	processInclude(require('./components/retailAds'));

	processInclude(require('base/components/cookie'));
	processInclude(require('base/components/consentTracking'));
	processInclude(require('base/components/footer'));
	processInclude(require('./components/miniCart'));
	processInclude(require('base/components/collapsibleItem'));
	processInclude(require('base/components/search'));
	processInclude(require('./components/clientSideValidation'));
	processInclude(require('base/components/countrySelector'));
	processInclude(require('base/components/toolTip'));
	processInclude(require('./components/productDetailQuickView'));
	processInclude(require('./components/orderByEmail'));
	processInclude(require('wishlists/product/wishlistHeart'));
	processInclude(require('google_analytics/tracking/tracking'));
	processInclude(require('./components/vviinnAddToCart'));
    processInclude(require('./components/promoBanner'));
    processInclude(require('./carousel'));

    new ResizeObserver(() => {
        let vw = document.documentElement.clientWidth / 100;
        document.documentElement.style.setProperty('--vw', `${vw}px`);
    }).observe(document.documentElement);
});

require('base/thirdParty/bootstrap');
require('base/components/spinner');
